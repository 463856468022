<template>
  <Holder :content="history">
    <List :list-data="history" #="{ item }" style="padding-bottom: 120px">
      <div class="df-middle" style="height: 48px">
        <Money :v="item.amount" class="flex-1 f-bold" />
        <div class="flex-1 t-center f-md">{{ item.rechargeChannelName }}</div>
        <Time class="flex-2 t-right f-sm" format="dd-MM-yy HH:mm:ss" :time="item.createTime" />
      </div>
      <Line />
    </List>
  </Holder>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import Money from '@/components.global/Money'
import Time from '@/components/Time.vue'
import Line from '@/components/Line.vue'
import List from '@/components/AsyncNullableList.vue'
import Holder from '@/provider/Holder.vue'

export default defineComponent(
  {
    name: 'TheInHistoryItem',
    components: { Holder, List, Line, Time, Money },
    props: {
      history: {
        type: Array,
        require: true,
      },
    },
  },
)
</script>

<style>
</style>
