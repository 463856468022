<template>
  <Holder :content="history">
    <List :list-data="history" #="{ item }" style="padding-bottom: 120px">
      <div class="df-middle" style="height: 48px">
        <div style="min-width: 60px" class="f-bold">
          <Money :v="item.amount" />
        </div>
        <span class="f-md">{{item.channelName}}</span>
        <div class="flex-1 t-center">
          <span
            v-if="status(item.status)" class="desc f-xs"
            :class="status(item.status).class"
          >{{ translate(status(item.status).i18n) }}</span>
        </div>
        <Time class="f-sm t-right" format="dd-MM-yy HH:mm:ss" :time="item.createTime" />
      </div>
      <p v-if="showReason(item.status)" class="c-tip f-sm pb-12 df-middle" style="margin-top: -8px;">
        <Icon name="warn" sm class="mr-4" />
        {{item.remark}}
      </p>
      <Line />
    </List>
  </Holder>
</template>
<script lang="ts">
import { OutState } from '@/modules/out/out.api'
import { defineComponent } from 'vue'
import { OutStateDescriber } from '@/pages/transaction/history'
import Icon from '@/components/Icon.vue'
import Time from '@/components/Time.vue'
import Money from '@/components.global/Money'
import Line from '@/components/Line.vue'
import List from '@/components/AsyncNullableList.vue'
import Holder from '@/provider/Holder.vue'
import { translate } from '@/i18n'

export default defineComponent(
  {
    name: 'TheOutHistoryItem',
    components: { Holder, List, Line, Money, Time, Icon },
    props: {
      history: {
        type: Array,
        require: true,
      },
    },
    setup () {
      const status = (s: OutState) => {
        return OutStateDescriber[s]
      }

      const showReason = (s: OutState) => {
        return s === OutState.FAIL || s === OutState.REJECT
      }

      return { status, showReason, translate }
    },
  },
)
</script>

<style scoped lang="scss">
.desc {
  border-radius: 8px;
  padding: 0 8px;
}

.blue {
  background: var(--color-primary-thin);
}

.red {
  background: var(--color-asst-light);
}

.green {
  background: #DAF6E6;
}

.gray{
  background: var(--color-light);
}

</style>
