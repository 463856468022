<template>
  <PageWithHeader class="px-16">
    <template #title><t path="moneyhistory_2">Deposit and withdrawal history</t></template>
    <t custom #="{td, t}">
      <Tab :tabs="[t('moneyhistory_3') || 'Deposit', t('moneyhistory_4') || 'Withdrawal']"
        class="mt-8" @switch="getType" :active="type" />
    </t>
    <component :is="getComponent(type)" :history="list" />

    <div class="tips df-middle br-8 c-white px-16 py-12" @click="linkToFeedback" v-if="isIndia">
      <t path="moneyhistory_12" as="p" class="flex-1" style="line-height: 20px;">
        I have a deposit that has not yet been credited to my account.
      </t>
      <Icon name="caret-right" />
    </div>
  </PageWithHeader>
</template>
<script lang="ts">
import { InHistorySchema, InState, readInHistory } from '@/modules/in/in.api'
import { readOutHistory } from '@/modules/out/out.api'
import { defineComponent, ref } from 'vue'
import * as R from 'ramda'
import TheInHistoryItem from '@/pages/transaction/components/TheInHistoryItem.vue'
import TheOutHistoryItem from '@/pages/transaction/components/TheOutHistoryItem.vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Tab from '@/components/Tab.vue'
import { defaultToArray } from '@/common/common'
import Icon from '@/components/Icon.vue'
import router from '@/router'
import { isIndia as $isIndia } from '@/state'

type TList = Array<InHistorySchema>
const request = [readInHistory, readOutHistory]

export default defineComponent(
  {
    name: 'History',
    components: { Tab, PageWithHeader, TheInHistoryItem, TheOutHistoryItem, Icon },
    setup () {
      const type = ref(0)
      const list = ref<TList | null>(null)

      console.log($isIndia())

      const getComponent = (type: number) => {
        return [TheInHistoryItem, TheOutHistoryItem][type]
      }

      const setList = (res: TList) => {
        list.value = res
      }

      const getList = (type: number) => {
        request[type](
          {
            page: 1,
            pageCount: 100,
            status: InState.SUCCESS,
          },
        )
          .then((resp: InHistorySchema[]) => R.o(setList, defaultToArray)(resp))
          .catch(R.o(setList, R.always([])))
      }

      const getType = (index: number) => {
        type.value = index
        list.value = null
        getList(index)
      }

      const linkToFeedback = () => {
        router.push('/me/io/feedback')
      }

      getList(type.value)

      return {
        getType,
        type,
        getComponent,
        list,
        linkToFeedback,
        isIndia: $isIndia(),
      }
    },
  },
)
</script>

<style scoped lang="scss">
.tips{
  background-color: var(--color-primary);
  position: fixed;
  left: 16px;
  right: 16px;
  bottom: 24px;
  border-radius: 6px;
}
</style>
